import PageViewH5P from "@/components/PageViewH5P.vue";
import { createWebHistory, createRouter } from "vue-router";


const routes = [
  {
    path: "/viewh5p",
    name: "PageViewH5P",
    component: PageViewH5P,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;