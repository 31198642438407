<template>
  <div class="hello">
    <div id="h5p-container"></div>
  </div>
</template>

<script>
import { H5P } from "h5p-standalone";
export default{
  data(){

  },
  mounted(){
    let nameFile = this.$route.query.nameFile
    if(nameFile){
      //`h5p/contents/memory-baby-goats`
      const el = document.getElementById("h5p-container");
      const options = {
        h5pJsonPath: `h5p/contents/${nameFile}`,
        frameJs: "h5p/frame.bundle.js",
        frameCss: "h5p/styles/h5p.css",
      };
      new H5P(el, options);
    }
  }
  
}


</script>